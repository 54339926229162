.bad-response{
    animation: vibration 1s;
  }
  
  @keyframes vibration {
    0% {
      transform: translateX(-15px);
    }
    10% {
      transform: translateX(15px);
    }
    20% {
      transform: translateX(-11px);
    }
    30% {
      transform: translateX(11px);
    }
    40% {
      transform: translateX(-8px);
    }
    50% {
      transform: translateX(8px);
    }
    60% {
      transform: translateX(-4px);
    }
    70% {
      transform: translateX(4px);
    }
    80% {
      transform: translateX(-2px);
    }
    90% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(0px);
    }
  }

.good-response {
    display: inline-block;
    animation: heartbeat 1s ease-in-out;
}

@keyframes heartbeat {

    0% {
      transform: scale(0.85);
    }
    20% {
      transform: scale(1);
    }
    40% {
      transform: scale(0.90);
    }
    60% {
      transform: scale(1);
    }
    80% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
