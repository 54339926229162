@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Prompt', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  background-color: white;
  text-align: center;
}

h1, h2, h3, h4, h5, h6,p {
  font-family: 'Prompt', sans-serif;
  line-height: 40.96px;
}

.button {
  display: inline-block;
  background: #4285f4;
  color: #fff;
  text-transform: uppercase;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  -webkit-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
}
.button:hover {
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0px, -2px) scale(1.05);
          transform: translate(0px, -2px) scale(1.05);
}

/* Barre de défilement elle-même */
::-webkit-scrollbar {
  width: 10px; /* Largeur de la barre de défilement verticale */
  height: 10px; /* Hauteur de la barre de défilement horizontale */
}

/* Poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
  background-color: #8626EC; 
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px; /* Arrondir les coins */
  border: 2px solid transparent; /* Ajustement pour éviter les débordements lors du déplacement */
}

/* Poignée de la barre de défilement au survol */
::-webkit-scrollbar-thumb:hover {
  background-color: #8611EC;
}

/* Track/background de la barre de défilement */
::-webkit-scrollbar-track {
  background: darkgrey;
  border-radius: 10px;
}
